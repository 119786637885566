import React from "react"
import { Link } from 'react-router-dom'
import { useSetSidebarContext } from '../../contexts/SidebarContext'

const SidebarTitle = props => {
    const setSidebar = useSetSidebarContext();
    return (
        <div className="flex items-center justify-center h-14 border-b border-gray-300 shadow-md bg-blue-50 mb-1 bg-gradient-to-br from-blue-300 to-green-50">
            <Link onClick={() => setSidebar(false)} to={props.url}>
                <div className="font-cursive text-gray-700 text-2xl font-extrabold tracking-wider flex-shrink-0 flex items-center px-4">
                    {props.name}
                </div>
            </Link>
        </div>
    )
};

export default SidebarTitle;
