import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

const config = {
  apiKey: "AIzaSyDF914XHmobwbczbIfYMy_f8s_jO_myOD8",
  authDomain: "tsrings-react.firebaseapp.com",
  projectId: "tsrings-react",
  storageBucket: "tsrings-react.appspot.com",
  messagingSenderId: "460489114479",
  appId: "1:460489114479:web:27edff18292ae28f59de9a",
  measurementId: "G-7WDLFTRFCG"
};

firebase.initializeApp(config);
export const firestore = firebase.firestore();
export const storage = firebase.storage().ref();
export const auth = firebase.auth();
export const stripeKey = 'pk_live_51GschTDa4nKoSaqFvlgvEVnQcPmTqQ5WhzujuVoCmTnqeOFFuKYB0HPcMjSxRkC1ih3oqhpOqrrSlsMkPbe4jh2100KehwUbsI';

//#########################################//
//############### FUNCTIONS ###############//
//#########################################//

export const addProduct = async (product, images) => {
  let cleanedProductName = product.name.toLowerCase().trim().replace(/[^a-zA-Z0-9]+/g, "_");
  return await productDocumentExists(cleanedProductName)
  .then(async exist => {
    if (!exist) {
      await uploadImages(cleanedProductName, images)
          .then(async () => {
            console.log("All imageages uploaded successfully");
            await setImageReferences(cleanedProductName, images)
              .then(async result => {
                console.log("All references set succesfully");
                product.images = result.images;
                product.thumbs = result.thumbs;
                await addProductDocument(cleanedProductName, product)
                  .then(() => {
                    console.log("Document added successfully");
                  })
                  .catch(error => {return Promise.reject(error)});
              })
              .catch(error => {return Promise.reject(error)});
          })
          .catch(error => {return Promise.reject(error)});
        } else {
          throw new Error("A product with the same name already exists")
        }
      })
      .catch(error => {return Promise.reject(error)});
    }

//#########################################//
//############### FIRESTORE ###############//
//#########################################//

const products = firestore.collection("products");
const productDocumentExists = async productName => {
  return await products.doc(productName).get()
    .then(doc => doc.exists)
    .catch(error => {return Promise.reject(error)});
}

const addProductDocument = async (productName, product) => {
  return await products.doc(productName).set({
    name: product.name,
    description: product.description,
    price: product.price,
    type: product.type,
    images: product.images,
    thumbs: product.thumbs,
    inventory: product.inventory,
    enabled: false,
  })
    .catch(error => {throw new Error("Failed to add document, error was " + error)})
}

//#########################################//
//################ STORAGE ################//
//#########################################//
const uploadImages = async (folderName, images) => {
  return await Promise.all(images.map(async (file, index) => {
    await uploadImage(folderName, index, file);
  }))
    .catch(error => {throw new Error("Failed to upload images, error was " + error)});
}

const setImageReferences = async (folderName, images) => {
  let refs = { images: [], thumbs: [] };
  return await Promise.all(images.map(async (file, index) => {
    await setImageReference(folderName, index, "800x800").then(url => refs.images.push(url));
    await setImageReference(folderName, index, "200x200").then(url => refs.thumbs.push(url));
  }))
    .then(() => refs)
    .catch(error => {throw new Error("Failed to set image references, error was " + error)});
}

const uploadImage = async (folderName, imageNumber, image) => {
  let folderPath = `images/${folderName}/`;
  let path = folderPath + `image_${imageNumber}.jpeg`;
  let ref = storage.child(path);
  return await ref.put(image)
    .catch(error => {return Promise.reject(error)});
}

const setImageReference = async (folderName, index, size) => {
  let folderPath = `images/${folderName}/`;
  let path = folderPath + `image_${index}_${size}.jpeg`;
  let ref = storage.child(path);
  return await attemptReference(10, ref)
    .then(url => url)
    .catch(error => {return Promise.reject(error)});
}

const attemptReference = (attempts, ref) => {
  if (attempts < 0) return Promise.reject('Attempted reference to many times');

  return ref.getDownloadURL()
  .then(url => url)
  .catch(error => {
    switch (error.code) {
      case 'storage/object-not-found':
        return delay(3000)
        .then(() => attemptReference(attempts - 1, ref));
      default:
        return Promise.reject(error);
    }
  })
};

const delay = (t, v) => {
  return new Promise(resolve => {
    setTimeout(resolve.bind(null, v), t)
  });
};