import React from 'react'
import { Link } from 'react-router-dom'
import { useSetSidebarContext } from '../../contexts/SidebarContext'
import { useCartContext } from '../../contexts/CartContext'

const Topbar = props => {
    const setSidebar = useSetSidebarContext();
    const cart = useCartContext();

    return (
        <div className="bg-white w-full border-b shadow-md">
            <div className="md:hidden flex flex-row justify-between items-center">
                <div className="w-14 h-14">
                    <div onClick={() => setSidebar(true)} className="h-full w-full flex justify-center items-center text-gray-500 rounded-m">
                        <i className="fas fa-bars"></i>
                    </div>
                </div>
                <div className="h-14 flex-grow text-gray-800 text-xl font-black font-cursive">
                    <Link to="/" className="h-full w-full flex justify-center items-center">
                        TSRings
                    </Link>
                </div>
                <div className="w-14 h-14">
                    <Link to="/cart" className="h-full w-full flex justify-center items-center text-gray-500 rounded-m">
                        {cart === undefined || cart.numItems === 0 ? null :
                            <div className="absolute top-3 right-2 border border-blue-100 rounded-full w-4 h-4 inline-flex justify-center items-center text-xs text-blue-700 bg-gradient-to-br from-blue-300 to-green-200">
                                {cart?.numItems}
                            </div>
                        }
                        <i className="fas fa-shopping-cart"></i>
                    </Link>
                </div>
            </div>
        </div >
    )
}

export default Topbar;