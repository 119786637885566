import React from 'react'
import { Link } from 'react-router-dom'
import { useSetSidebarContext } from '../../contexts/SidebarContext'

const SidebarAccount = props => {
    const setSidebar = useSetSidebarContext();
    return (
        <div className="flex border-t border-gray-300 py-3 px-4 shadow-inner bg-gradient-to-br from-gray-50 to-gray-200">
            <Link onClick={() => setSidebar(false)} to="/account" className="flex-shrink-0 group block">
                <div className="flex items-center">
                    <div className="ml-3 text-gray-600">
                        Profiles coming soon!
                        {/* <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            Trevor Jensen
                        </p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            View profile
                        </p> */}
                    </div>
                </div>
            </Link>
        </div>
    )
};

export default SidebarAccount;