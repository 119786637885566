import { useState, useEffect, useRef } from 'react'
import { firestore } from '../firebase'
import { useSetNotificationContext } from '../contexts/NotificationContext'
import { useHistory } from 'react-router-dom';

export const useGetDocument = (collection, id) => {
    const cache = useRef({});
    const [data, setData] = useState();
    const notify = useSetNotificationContext();
    const history = useHistory();

    const fetchDocument = async (collection, id) => {
        let document;
        if (cache.current[id]) {
            document = cache.current[id];
        } else {
            document = await firestore.collection(collection).doc(id).get()
            .then(doc => {
                if (doc.exists) {
                    return doc.data();
                } else {
                    notify("Cannot find the specified product!", "error");
                    setTimeout(() => history.push("/"),2100)
                }
            });
            cache.current[id] = document;
        }
        setData(document);
    }

    useEffect(() => {
        fetchDocument(collection,id);
    },[collection,id]);

    return data;
}