import React from 'react'
import { auth } from '../../firebase'
import { useHistory } from 'react-router-dom'
import { useSetNotificationContext } from '../../contexts/NotificationContext'
import PageTitle from '../../components/PageTitle/PageTitle'

const SignIn = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [user, setUser] = React.useState('');

    const history = useHistory();
    const notify = useSetNotificationContext();

    const submit = () => {
        auth.signInWithEmailAndPassword(email, password).then(userCred => {
            notify(userCred.user.email + " was signed in!", 'success');
            setUser(userCred.user);
            history.push("/");
        }).catch(error => {
            notify(error.message, 'error');
        })
    }

    return (
        <div>
            <PageTitle title="Sign In" />
            <div className="p-3">
                <div className="space-y-3">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <div className="mt-1">
                            <input onChange={e => setEmail(e.target.value)} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400" />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <div className="mt-1">
                            <input onChange={e => setPassword(e.target.value)} type="password" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400" />
                        </div>
                    </div>
                    <div className="pt-2">
                        <button onClick={() => submit()} className="w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium bg-gradient-to-br from-blue-300 to-green-100 text-gray-600">
                            Sign in
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SignIn;