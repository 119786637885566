import { React, useState } from 'react'
import { useCartContext, useSetCartContext } from '../../contexts/CartContext'
import { useCheckoutContext, useSetCheckoutContext } from '../../contexts/CheckoutContext'
import { useSetNotificationContext } from '../../contexts/NotificationContext'
import { useSetLoaderContext } from '../../contexts/LoaderContext'
import Confetti from 'react-dom-confetti';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';

// Stripe
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { firestore } from '../../firebase'
const stripeCreatePaymentIntentURL = 'https://us-central1-tsrings-react.cloudfunctions.net/createPaymentIntent';

// Validation
const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRE = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

const Checkout = props => {
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [amount, setAmount] = useState('');
    const [products, setProducts] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    // const [createAccount, setCreateAccount] = useState(false);
    // const [password, setPassword] = useState();

    const cart = useCartContext();
    const setCart = useSetCartContext();
    const checkout = useCheckoutContext();
    const setCheckout = useSetCheckoutContext();
    const notify = useSetNotificationContext();
    const setLoader = useSetLoaderContext();

    const handleChange = async (name, field, value) => {
        let cur = { ...checkout };
        if (field === 'fname' || field === 'lname') value = value.charAt(0).toUpperCase() + value.slice(1);
        if (name === 'shipping' && field === 'country' && value === 'Canada') cur.shipping.state = '';
        if (name === 'shipping' && field === 'country' && value === 'USA') cur.shipping.state = 'UT';
        if (name === 'customer' && field === 'email') value = value.trim();
        cur[name][field] = String(value);
        await setCheckout(cur);
    }

    const proceedToPayment = async e => {
        // Check for items in cart
        if (Object.entries(cart.items).length < 1) return notify("You don't have any items in your cart!", 'warning');

        // Validate inputs
        if (checkout.customer.fname === '') return notify("You must enter a first name!", 'warning');
        if (checkout.customer.lname === '') return notify("You must enter a last name!", 'warning');
        if (!emailRE.test(String(checkout.customer.email).toLowerCase())) return notify("You must enter a valid email!", 'warning');
        if (!phoneRE.test(String(checkout.customer.phone).toLowerCase())) return notify("You must eneter a valid phone number!", 'warning');
        if (checkout.shipping.street === '') return notify("You must enter a street!", 'warning');
        if (checkout.shipping.city === '') return notify("You must enter a street!", 'warning');
        if (checkout.shipping.zip === '') return notify("You must enter a valid zip code!", 'warning');
        if (checkout.shipping.country === '') await handleChange('shipping', 'country', 'USA');
        if (checkout.shipping.country === 'USA' && checkout.shipping.state === '') return notify("You must enter a state!", 'warning');

        // Add customer to database
        setLoader(true);
        const customerRef = await firestore.collection('customers').doc(checkout.customer.email);
        await customerRef.get().then(async doc => {
            if (!doc.exists) {
                await customerRef.set({
                    ...checkout.customer,
                    address: { ...checkout.shipping },
                    stripeId: '',
                }).catch(error => notify(error.message, 'error'));
            }
        })

        // Create a payment intent
        await fetch(stripeCreatePaymentIntentURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                items: cart.items,
                outsideUS: checkout.shipping.country !== 'USA' ? true : false,
                customerEmail: checkout.customer.email.trim(),
            })
        }).then(res => {
            return res.json();
        }).then(data => {
            setClientSecret(data.clientSecret);
            setAmount(data.amount);
            setProducts(data.items);
        }).catch(error => notify("There was an error creating your order, please refresh the page and try again" + error.message, 'error'));
        setLoader(false);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setProcessing(true);

        // Check inventory
        await new Promise(async (success, failure) => {
            const promises = [];
            Object.entries(products).forEach(async (item, index) => {
                promises.push(new Promise(async (resolve, reject) => {
                    const ref = firestore.collection('products').doc(item[0]);
                    const doc = await ref.get();
                    if (!doc.exists) reject(item[1].name + ' is no longer available!');
                    const data = await doc.data();
                    let inventory = data.inventory;

                    const itemPromises = [];
                    Object.entries(item[1].sizes).forEach(([name, qty]) => {
                        itemPromises.push(new Promise((res, rej) => {
                            const size = inventory.find(element => element.size.toLowerCase().trim() === name.toLowerCase().trim());
                            const index = inventory.indexOf(size);
                            if (size === undefined) rej("An error occured!");
                            if (inventory[index].qty < qty) rej(item[1].name + ' in size ' + name + ' is no longer available at the chosen quantity! Please remove it from your cart');
                            res();
                        })
                        )
                    });
                    await Promise.all(itemPromises)
                        .then(() => resolve())
                        .catch(error => reject(error));
                }));
            })
            await Promise.all(promises).then(() => {
                success();
            }).catch(error => {
                failure(error);
            });
        }).then(async () => {

            // Process Card
            const payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: checkout.customer.fname + ' ' + checkout.customer.lname,
                    }
                }
            });

            if (payload.error) {
                notify(payload.error.message, 'error');
                setProcessing(false);
            } else {

                // Remove items from inventory
                Object.entries(products).forEach(async (item, index) => {
                    const ref = firestore.collection('products').doc(item[0]);
                    const doc = await ref.get();
                    const data = await doc.data();
                    let inventory = data.inventory;
                    Object.entries(item[1].sizes).forEach(([name, qty]) => {
                        const size = inventory.find(element => element.size.toLowerCase().trim() === name.toLowerCase().trim());
                        const index = inventory.indexOf(size);
                        inventory[index].qty -= qty;
                    })
                    let total = 0;
                    inventory.forEach(size => total += size.qty);
                    const enabled = total > 0;
                    ref.update({ inventory: inventory, enabled: enabled })
                })

                // Create order
                let today = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0');
                let yyyy = today.getFullYear();
                let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                today = mm + '-' + dd + '-' + yyyy + "_" + time;
                let orderId = today + "_" + checkout.customer.email;
                firestore.collection("orders").doc(orderId).set({
                    email: checkout.customer.email.trim(),
                    address: checkout.shipping,
                    items: products,
                    purchaseDate: today,
                    amount: amount / 100,
                    shipDate: '',
                });

                // Empty cart
                setCart({
                    numItems: 0,
                    items: {},
                })

                notify("The payment was successful!", 'success');
                setSucceeded(true);
            }
        }).catch(error => {
            notify(error, 'error')
        });
        setProcessing(false);
    };

    const handleCardChange = async e => {
        setDisabled(e.empty);
        if (e.error) notify(e.error.message, "warning");
    };

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    const confetti = {
        angle: 90,
        spread: 360,
        startVelocity: 35,
        elementCount: 70,
        dragFriction: 0.3,
        duration: 3000,
        stagger: 3,
        width: "8px",
        height: "8px",
        perspective: "1000px",
        colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
    };

    return (
        <div>
            <PageTitle title="Checkout" />
            <div className="mx-3">
                <div className="border rounded-md bg-gray-50 p-3 mb-6 space-y-3">
                    <div>
                        <h3 className="text-lg font-medium text-gray-900 text-center mb-2">Personal Information</h3>
                    </div>
                    {/* <div className="w-full">
                        <button className="w-full border rounded-md shadow p-2 text-center text-sm bg-black text-white" disabled={amount !== ''}>
                            Sign In
                        </button>
                    </div>
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="px-2 bg-gray-50 text-sm text-gray-500">Or</span>
                        </div>
                    </div> */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-0.5">First name</label>
                        <input value={checkout.customer.fname} name="fname" onChange={e => handleChange("customer", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-0.5">Last name</label>
                        <input value={checkout.customer.lname} name="lname" onChange={e => handleChange("customer", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-0.5">Email</label>
                        <input value={checkout.customer.email} name="email" type="email" onChange={e => handleChange("customer", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-0.5">Phone</label>
                        <input value={checkout.customer.phone} name="phone" onChange={e => handleChange("customer", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                    </div>
                    {/* <div className="flex justify-between items-center">
                        <div className="text-gray-500 text-sm">
                            Create a new Account?
                        </div>
                        <Toggle enabled={createAccount} toggle={() => setCreateAccount(!createAccount)} disabled={amount !== ''} />
                    </div> */}
                    {/* {!createAccount ? null :
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-0.5">Password</label>
                            <input className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                        </div>
                    } */}
                </div>
                <div className="border rounded bg-gray-50 p-3 space-y-3">
                    <div>
                        <h3 className="text-lg font-medium text-gray-900 text-center mb-1">Shipping Information</h3>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-0.5">Street</label>
                        <input value={checkout.shipping.street} name="street" onChange={e => handleChange("shipping", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                    </div>
                    <div className="flex w-full justify-between space-x-2">
                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-0.5">City</label>
                            <input value={checkout.shipping.city} name="city" onChange={e => handleChange("shipping", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                        </div>
                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-0.5">Zip</label>
                            <input value={checkout.shipping.zip} name="zip" onChange={e => handleChange("shipping", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''} />
                        </div>
                    </div>
                    <div className="flex w-full justify-between space-x-2">
                        <div className="w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-0.5">Country</label>
                            <select value={checkout.shipping.country} name="country" onChange={e => handleChange("shipping", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''}>
                                <option>USA</option>
                                <option>Canada</option>
                            </select>
                        </div>
                        {checkout.shipping.country === 'Canada' ? null :
                            <div className="w-full">
                                <label className="block text-sm font-medium text-gray-700 mb-0.5">State</label>
                                <select value={checkout.shipping.state} name="state" onChange={e => handleChange("shipping", e.target.name, e.target.value)} className="p-1 max-w-lg block w-full shadow-sm border-gray-300 rounded-md" disabled={amount !== ''}>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                        }
                    </div>
                </div>
                {clientSecret === '' || clientSecret === null ?
                    <div className="w-full">
                        <button onClick={() => proceedToPayment()} className="text-center border rounded-md p-2 shadow-lg mt-4 w-full bg-gradient-to-br from-blue-300 to-green-100 text-gray-600">
                            Proceed to Payment
                        </button>
                    </div> :
                    <div>
                        <div className="inline-flex justify-center items-center w-full">
                            <Confetti active={succeeded} config={confetti} />
                        </div>
                        <div className="border rounded-md bg-gray-50 space-y-3 p-3">
                            <div>
                                <h3 className="text-lg font-medium text-gray-900 text-center mb-1">Card Details</h3>
                            </div>
                            <div className="text-gray-500">
                                Total: ${(amount / 100).toFixed(2)}
                            </div>
                            <div>
                                <form id="payment-form" onSubmit={handleSubmit}>
                                    <CardElement id="card-element" options={cardStyle} onChange={handleCardChange} />
                                    <button className="w-full p-2 h-12 font-lg border rounded-md mt-4 bg-gradient-to-br from-blue-300 to-green-100" disabled={processing || disabled || succeeded} id="submit">
                                        {processing ?
                                            <div className="text-gray-400 w-full inline-flex flex-row items-center justify-center">
                                                <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                            Processing
                                        </div> :
                                            <div className={`${disabled || succeeded ? "text-gray-400" : "text-gray-700"}`}>
                                                Pay Now
                                        </div>}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                }
                {!succeeded ? <div className="mt-20" /> :
                    <div className="text-gray-700 text-center mt-3 mb-20">
                        Congratulations! Your order has been submitted! Your order will be ready to ship soon.
                    </div>
                }
            </div>
        </div >
    )
};

export default Checkout;

        // // Check items inventory
        // let successful = true;
        // await firestore.runTransaction(transaction => {
        //     return new Promise((resolve, reject) => {
        //         Object.entries(cart.items).forEach((item, index) => {
        //             let ref = firestore.collection('products').doc(item[0]);
        //             transaction.get(ref).then(doc => {
        //                 if (!doc.exists) throw Error(item[1].name + ' is no longer available!');
        //                 const data = doc.data();
        //                 let inventory = data.inventory;
        //                 try {
        //                     Object.entries(item[1].sizes).forEach(([name, qty]) => {
        //                         const size = inventory.find(element => element.size === name);
        //                         const index = inventory.indexOf(size);
        //                         if (size === undefined) throw Error("An error occured!");
        //                         if (inventory[index].qty < qty) throw Error(item[1].name + ' in size ' + name + ' is no longer available at the chosen quantity!');
        //                         inventory[index].qty -= qty;
        //                     })
        //                 } catch (error) {
        //                     reject(error);
        //                 }
        //                 let total = 0;
        //                 inventory.forEach(size => total += size.qty);
        //                 const enabled = total > 0;
        //                 transaction.update(ref, { ...data, inventory: inventory, enabled: enabled })
        //                 resolve();
        //             }).catch(error => { return Promise.reject(error) })
        //         })
        //     }).catch(error => { throw Error(error) });
        // }).catch(error => {
        //     successful = false;
        //     return notify(error.message, 'error');
        // });
        // if (!successful) return;