import React from 'react'
import { Link } from 'react-router-dom';
import { useSetLoaderContext } from '../../contexts/LoaderContext'

const Home = props => {
    const [loaded, setLoaded] = React.useState(false);
    const setLoaderContext = useSetLoaderContext();

    React.useEffect(() => {
        loaded ? setLoaderContext(false) : setLoaderContext(true);
    }, [loaded])

    let numImagesLoaded = 0;
    const loadImage = () => {
        numImagesLoaded += 1;
        if (numImagesLoaded >= 3) setLoaded(true);
    }

    return (
        <div className={`${loaded ? 'opacity-100 p-3' : 'invisible opacity-0 duration-200'} transition-opacity flex flex-col justify-center items-center`}>
            <div className="text-xl text-gray-500">Welcome</div>
            <div className="mb-3 font-cursive font-bold text-2xl text-gray-700">
                Trevor's Spoon Rings
            </div>
            <div className="flex flex-col w-full px-2">
                <Link to="/products?type=ring&page=1" className="relative w-full border rounded-md shadow-md bg-white mb-4 text-3xl">
                    <div className="absolute flex justify-center items-center w-full h-full">
                        <div className="text-4xl border h-24 w-44 rounded-md bg-gray-50 opacity-50" />
                        <div className="absolute inline-flex justify-center items-center w-full h-full text-gray-700">
                            Rings
                        </div>
                    </div>
                    <img onLoad={() => loadImage()} className="object-cover h-60 w-full rounded-md" src="https://firebasestorage.googleapis.com/v0/b/tsrings-react.appspot.com/o/images%2FTSRings-Rings_800x800.jpeg?alt=media&token=b3836897-0062-4aec-b2d8-beb0afa47aa4" alt="rings" />
                </Link>
                <Link to="/products?type=necklace&page=1" className="relative w-full border rounded-md shadow-md bg-white mb-4 text-3xl">
                    <div className="absolute flex justify-center items-center w-full h-full">
                        <div className="text-4xl border h-24 w-44 rounded-md bg-gray-50 opacity-50" />
                        <div className="absolute inline-flex justify-center items-center w-full h-full text-gray-700">
                            Necklaces
                        </div>
                    </div>
                    <img onLoad={() => loadImage()} className="object-cover h-60 w-full rounded-md" src="https://firebasestorage.googleapis.com/v0/b/tsrings-react.appspot.com/o/images%2FTSRings-Necklaces_800x800.jpeg?alt=media&token=9a167044-55c8-4e00-9c5c-76bd028b0201" alt="rings" />
                </Link>
                <Link to="/products?type=other&page=1" className="relative w-full border rounded-md shadow-md bg-white mb-4 text-3xl">
                    <div className="absolute flex justify-center items-center w-full h-full">
                        <div className="text-4xl border h-24 w-44 rounded-md bg-gray-50 opacity-50" />
                        <div className="absolute inline-flex justify-center items-center w-full h-full text-gray-700">
                            Other
                        </div>
                    </div>
                    <img onLoad={() => loadImage()} className="object-cover h-60 w-full rounded-md" src="https://firebasestorage.googleapis.com/v0/b/tsrings-react.appspot.com/o/images%2FTSRings-Other_800x800.jpeg?alt=media&token=5a49ed63-03ba-4739-a868-0350f139f100" alt="rings" />
                </Link>
            </div>
        </div>
    )
};

export default Home;