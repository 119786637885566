import React from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useGetCollection } from '../../hooks/useGetCollection'
import { useSetLoaderContext } from '../../contexts/LoaderContext'
import NotFound from '../NotFound/NotFound'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Pagination from '../../components/Pagination/Pagination'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Products = () => {
    const [loaded, setLoaded] = React.useState(false);
    const [imagesLoaded, setImagesLoaded] = React.useState(0);
    const setLoaderContext = useSetLoaderContext();
    const history = useHistory();

    let query = useQuery();
    const type = query.get("type");
    const queryPage = query.get("page");
    const page = queryPage === null ? 1 : queryPage;
    const itemsPerPage = 20;
    const baseURL = `/products${type !== null ? "?type=" + type + "&page=" : "?page="}`;
    const { data, numPages } = useGetCollection("products", page, itemsPerPage, [["type", type], ["enabled", true]]);

    React.useEffect(() => {
        setLoaded(false);
        setImagesLoaded(0);
    }, [type, page])

    React.useEffect(() => {
        if (data === undefined || numPages === undefined) return;
        if (page > numPages) history.push(baseURL + 1);
        if (page < 1) history.push(baseURL + numPages);
        if (Object.keys(data).length === 0) setLoaded(true);
        console.log(data);
    }, [type, page, data]);

    React.useEffect(() => {
        loaded ? setLoaderContext(false) : setLoaderContext(true);
    }, [loaded, setLoaderContext])

    React.useEffect(() => {
        if (data === undefined || numPages === undefined) return;
        if (imagesLoaded >= Object.keys(data).length) setLoaded(true);
        else setLoaded(false);
    }, [imagesLoaded, type, page, numPages, data])

    return (
        <div>
            <ScrollToTop />
            {data === undefined ? null :
                Object.keys(data).length === 0 ?
                    <NotFound /> :
                    <div className={`${loaded ? 'opacity-100 p-3' : 'invisible opacity-0 duration-200'} transition-opacity`}>
                        <Pagination page={page} numPages={numPages} baseURL={baseURL} />
                        <div className="flex flex-wrap justify-center">
                            {Object.entries(data).map(([key, item], index) =>
                                <Link key={index} to={`/products/${item?.name.toLowerCase().trim().replace(/[^a-zA-Z0-9]+/g, "_")}`}
                                    className="relative flex flex-col w-full rounded-lg bg-white mb-5 shadow-lg border border-t-0 border-gray-300">
                                    <div className="w-full text-gray-700 text-md font-medium rounded-t-md p-1.5 text-center capitalize bg-gradient-to-br from-blue-200 to-green-100 border-b border-gray-300">
                                        {item.name}
                                    </div>
                                    <div>
                                        <img src={item.images[0]} alt={item.name} onLoad={() => {
                                            setImagesLoaded(prev => prev + 1);
                                        }} className="w-full h-full object-cover" />
                                    </div>
                                    <div className="rounded-b-lg flex items-center border-t border-gray-300 bg-gradient-to-br from-gray-200 to-gray-100">
                                        <div className="text-gray-700 border border-t rounded-bl-md rounded-r py-1.5 px-2 bg-gradient-to-br from-gray-100 to-white">
                                            ${parseFloat((item?.price)).toFixed(2)}
                                        </div>
                                        <div className="flex justify-around px-2 h-full items-center w-full">
                                            <div className="pr-1 h-full inline-flex items-center justify-center text-gray-600">
                                                Sizes:
                                                </div>
                                            <div className="flex h-full items-center justify-center">
                                                {item?.inventory.map(inv => {
                                                    return (
                                                        <div key={inv.size} className="border text-sm text-white bg-gray-500 rounded-lg px-2 mx-0.5 capitalize">
                                                            {inv.size}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )}
                        </div>
                        <Pagination page={page} numPages={numPages} baseURL={baseURL} />
                    </div>
            }
        </div>
    )
};

export default Products;