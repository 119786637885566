import React from 'react'
import { useGetDocument } from '../../hooks/useGetDocument'
import { useParams } from 'react-router';
import { useSetNotificationContext } from '../../contexts/NotificationContext'
import { useSetCartContext, useCartContext } from '../../contexts/CartContext'
import { useHistory } from 'react-router-dom';

const Product = () => {
    const [loaded, setLoaded] = React.useState();
    const [size, setSize] = React.useState();

    const { id } = useParams();
    const setCart = useSetCartContext();
    const cart = useCartContext();
    const notify = useSetNotificationContext();
    const data = useGetDocument("products", id);
    const history = useHistory();

    React.useEffect(() => {
        if (data === undefined || cart === undefined) return;
        const cartProduct = cart.items[id];
        if (cartProduct !== undefined) {
            Object.entries(cartProduct.sizes).forEach(entry => {
                const cartSize = entry[0];
                const cartQty = entry[1];
                const pageSize = data.inventory.find(sz => sz.size === cartSize);
                if (pageSize !== undefined) {
                    pageSize.qty = parseInt(pageSize.qty) - cartQty;
                }
            })
        }
        setTotalQty();
    }, [data])

    const setTotalQty = () => {
        let total = 0;
        data.inventory.forEach(item => {
            total += parseInt(item.qty)
        });
        data.totalInventory = total;
    }

    const addToCart = buyNow => {
        // Make sure the user entered a size
        if (size === undefined) return notify("You must select a size!", "warning");
        let newProduct;

        // Check if product already exists in cart
        if (cart.items[id] !== undefined) {
            newProduct = { ...cart.items[id] };
            // Product already in cart
            // Check if the user already has the same size of product in the cart
            if (newProduct.sizes[size] !== undefined) {
                // Product with same size already in cart
                // Increase the qty
                newProduct.sizes[size] += 1;
            } else {
                // Product with same size not in cart
                // Put the product with specified size in the cart
                newProduct.sizes = { ...newProduct.sizes, [size]: 1 }
            }
        } else {
            // Product not in cart
            // Add product to the cart
            newProduct = { ...data }
            newProduct.sizes = { [size]: 1 };
            delete newProduct.inventory;
            delete newProduct.totalInventory;
            delete newProduct.enabled;
        }
        data.inventory.find(inv => inv.size === size).qty -= 1;
        setTotalQty();
        setSize(undefined);
        setCart(prev => ({ ...prev, numItems: cart.numItems + 1, items: { ...cart.items, [id]: newProduct } }))
        if (!buyNow) return;
        history.push("/checkout")
    }

    return (
        <div>
            {data === undefined ? null :
                <div className={`${loaded ? 'opacity-100 m-3' : 'invisible opacity-0 duration-200 scale-75'} transition-opacity relative flex flex-col border m-2 rounded-lg bg-white`}>
                    <div className="flex-none">
                        <img src={data.images[0]} alt={data.name} onLoad={() => setLoaded(true)} className="w-full h-full object-cover rounded-t-lg" />
                    </div>
                    <div className="flex-auto p-6">
                        <div className="flex flex-wrap">
                            <h1 className="flex-auto text-xl font-semibold mr-2 capitalize">
                                {data.name}
                            </h1>
                            <div className="text-xl font-semibold text-gray-600">
                                ${data.price}
                            </div>
                            <div className="text-sm text-gray-500 w-full mt-2">
                                {data.description}
                            </div>
                            <div className="w-full flex-none text-sm text-right font-medium text-gray-500 mt-3 italic">
                                {data.totalInventory !== 1 ? null :
                                    <div>* Only 1 left in stock!</div>
                                }
                            </div>
                        </div>
                        <div className="flex items-baseline mb-5">
                            <div className="space-x-2 flex justify-center border w-full border-gray-300 rounded px-3 py-2 bg-gradient-to-br from-blue-400 to-green-100">
                                <div className="px-2 font-semibold text-xl flex items-center justify-center text-white">
                                    Sizes
                                </div>
                                {data.inventory?.map(item => (
                                    item.qty < 1 ? null :
                                        <button key={item.size} onClick={() => setSize(item.size)} className={`${size === item.size ? "bg-gray-200 border-2 border-blue-400 shadow-lg" : "bg-white border border-gray-300 shadow-md"} py-1 px-1.5 flex items-center justify-center rounded-lg focus:outline-none`}>
                                            {item.size}
                                        </button>
                                ))}
                            </div>
                        </div>
                        <div className="flex space-x-3 mb-4 text-sm font-medium">
                            <div className="flex-auto flex justify-between space-x-3">
                                <button onClick={() => { addToCart(true) }} className="w-1/2 h-10 flex items-center justify-center rounded-md bg-black text-white shadow" type="submit">Buy now</button>
                                <button onClick={() => addToCart(false)} className="w-1/2 h-10 flex items-center justify-center rounded-md border border-gray-300 shadow" type="button">Add to cart</button>
                            </div>
                        </div>
                        <div className="text-sm text-center text-gray-500">
                            Free shipping on all continental US orders.
                    </div>
                    </div>
                    {data.images.map((image, index) => {
                        return (
                            <div key={index}>
                                {index === 0 && data.images.length > 1 ?
                                    <div className="text-md text-center text-gray 700 italic">
                                        Additional Images
                                </div> : ''
                                }
                                {index !== 0 ?
                                    <div className="flex-none m-2">
                                        <img src={image} alt={data.name} className="w-full h-full object-cover rounded-lg" />
                                    </div> : ''
                                }
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
};

export default Product;