import React from 'react'
import { firestore } from '../../firebase'
import { useGetDocument } from '../../hooks/useGetDocument'
import { useParams } from 'react-router';
import PageTitle from '../../components/PageTitle/PageTitle';

const Order = () => {
    const [order, setOrder] = React.useState(undefined);
    const [customer, setCustomer] = React.useState(undefined);

    const { id } = useParams();
    const data = useGetDocument("orders", id);

    React.useEffect(() => {
        async function getCustomer(email) {
            await firestore.collection("customers").doc(email.trim()).get().then(cust => {
                setCustomer(cust.data());
            });
        }
        if (data === undefined) return;
        setOrder(data);
        getCustomer(data.email);
    }, [data])

    return (
        <div>
            {order === undefined || customer === undefined ? null :
                <div className="mt-3">
                    <div className="flex flex-col items-center mx-3">
                        <div className="flex flex-col space-y-4 border rounded-md shadow-md bg-white mb-3 p-3 w-full text-gray-700">
                            <div className="flex flex-col space-y-1">
                                <div>
                                    Name:
                                    <span className="text-gray-500">
                                        {' ' + customer.fname + ' ' + customer.lname}
                                    </span>
                                </div>
                                <div>
                                    Email:
                                    <span className="text-gray-500">
                                        {' ' + customer.email}
                                    </span>
                                </div>
                                <div>
                                    Amount:
                                    <span className="text-blue-500">
                                        {' $' + order.amount}
                                    </span>
                                </div>
                                <div>
                                    Purchase Date:
                                    <span className="text-gray-500">
                                        {' ' + order.purchaseDate.replaceAll("-", "/")}
                                    </span>
                                </div>
                                <div>
                                    Ship Date:
                                    <span className="text-gray-500">
                                        {' ' + order.shipDate}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-0.5 text-gray-500 text-sm">
                                <div className="text-gray-700 text-base">
                                    ADDRESS:
                                </div>
                                <div>
                                    {customer.fname + ' ' + customer.lname}
                                </div>
                                <div>
                                    {order.address.street}
                                </div>
                                <div>
                                    {order.address.city + ', ' + order.address.state + ' ' + order.address.zip}
                                </div>
                                <div>
                                    {order.address.country}
                                </div>
                            </div>
                        </div>
                        {Object.entries(order.items).map(([key, value]) =>
                            <div key={key} className="w-full">
                                {Object.entries(value.sizes).map(([size, qty]) =>
                                    <div key={size} className="flex justify-between border rounded-md shadow-md bg-white mb-3">
                                        <div className="mr-1">
                                            <img src={value.thumbs[0]} className={`object-cover h-full w-full rounded-l`} alt={value.name} />
                                        </div>
                                        <div className="flex flex-col justify-between w-full rounded px-2 py-1">
                                            <div className="flex w-full justify-between">
                                                <div className="text-gray-700 capitalize">
                                                    {value.name}
                                                </div>
                                            </div>
                                            <div className="text-sm text-gray-400 pb-2">
                                                Size: {size[0]}
                                            </div>
                                            <div className="flex w-full justify-between">
                                                <div className="text-sm text-gray-500">
                                                    Quantity: {qty}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    )
};

export default Order;