import React from 'react'
import { useCartContext, useSetCartContext } from '../../contexts/CartContext'
import { useCheckoutContext } from '../../contexts/CheckoutContext'
import PageTitle from '../../components/PageTitle/PageTitle'
import { useSetLoaderContext } from '../../contexts/LoaderContext'
import { Link } from 'react-router-dom';
import Toggle from '../../components/Toggle/Toggle';

const Cart = props => {
    const [pricing, setPricing] = React.useState();
    const [nonUS, setNonUS] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const setLoaderContext = useSetLoaderContext();

    const cart = useCartContext();
    const setCart = useSetCartContext();
    const checkout = useCheckoutContext();

    React.useEffect(() => {
        if (Object.keys(cart.items).length === 0) setLoaded(true);
        if (checkout.shipping.country !== '' && checkout.shipping.country !== 'USA') setNonUS(true);
    });

    React.useEffect(() => {
        loaded ? setLoaderContext(false) : setLoaderContext(true);
    }, [loaded])

    React.useEffect(() => {
        if (cart === undefined) return;
        let subtotal = 0;
        Object.entries(cart.items).forEach(([name, item]) => {
            Object.entries(item.sizes).forEach(([size, qty]) => subtotal += qty * item.price)
        })
        subtotal = subtotal.toFixed(2);
        let tax = (subtotal * 0.0485).toFixed(2);
        let shipping = nonUS ? 15 : 0;
        let total = (+subtotal + +tax + +shipping).toFixed(2);
        setPricing({ subtotal: subtotal, tax: tax, shipping: shipping, total: total })
    }, [cart, nonUS])

    const deleteItem = (name, size) => {
        setCart(prev => {
            const c = { ...prev };
            c.numItems -= c.items[name].sizes[size];
            delete c.items[name].sizes[size];
            if (c.items[name].sizes && Object.keys(c.items[name].sizes).length === 0 && c.items[name].sizes.constructor === Object) delete c.items[name];
            return c;
        })
    }

    let numImagesLoaded = 0;
    const loadImage = () => {
        numImagesLoaded += 1;
        if (numImagesLoaded >= Object.keys(cart.items).length) setLoaded(true);
    }

    return (
        <div className={`${loaded ? 'opacity-100' : 'invisible opacity-0 duration-200'} transition-opacity`}>
            <PageTitle title="Cart" />
            <div className="flex flex-col items-center mx-3">
                {Object.entries(cart.items).map(([name, item]) =>
                    <div key={name} className="w-full">
                        {Object.entries(item.sizes).map(size =>
                            <div key={size} className="flex justify-between border rounded-md shadow-md bg-white mb-3">
                                <div className="mr-1">
                                    <img onLoad={() => loadImage()} src={item.thumbs[0]} className={`object-cover h-full w-full rounded-l`} alt={name} />
                                </div>
                                <div className="flex flex-col justify-between w-full rounded px-2 py-1">
                                    <div className="flex w-full justify-between">
                                        <div className="text-gray-700 capitalize">
                                            {item.name}
                                        </div>
                                        <div onClick={() => deleteItem(name, size[0])} className="text-gray-400">
                                            <i className="fas fa-trash"></i>
                                        </div>
                                    </div>
                                    <div className="text-sm text-gray-400 pb-2">
                                        Size: {size[0]}
                                    </div>
                                    <div className="flex w-full justify-between">
                                        <div className="text-sm text-gray-500">
                                            Quantity:  {size[1]}
                                        </div>
                                        <div className="text-blue-500">
                                            ${(item.price * size[1]).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {Object.keys(cart.items).length === 0 ?
                    <div className="text-center">
                        <div className="my-2 text-gray-400">Nothing in the cart...</div>
                    </div> :
                    <div className="w-full">
                        <div className="flex flex-col justify-between border rounded-md shadow-md bg-white mt-3 w-full">
                            <div className="border-b flex justify-between w-full p-2 text-gray-600">
                                <div>
                                    Subtotal
                                </div>
                                <div>
                                    ${pricing?.subtotal}
                                </div>
                            </div>
                            <div className="border-b flex justify-between w-full p-1 px-2 text-gray-400 text-sm">
                                <div>
                                    Sales Tax
                                </div>
                                <div>
                                    ${pricing?.tax}
                                </div>
                            </div>
                            <div className="border-b flex justify-between w-full p-1 px-2 text-gray-400 text-sm">
                                <div>
                                    Shipping
                                </div>
                                <div>
                                    ${pricing?.shipping}
                                </div>
                            </div>
                            <div className="flex justify-between w-full p-2 text-gray-800 text-lg">
                                <div>
                                    Total
                                </div>
                                <div className="text-blue-500">
                                    ${pricing?.total}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end items-center mt-1.5 mb-3">
                            <div className="mr-3 text-sm text-gray-500">
                                Live outside the USA?
                            </div>
                            <div>
                                <Toggle enabled={nonUS} toggle={() => setNonUS(!nonUS)} />
                            </div>
                        </div>
                        <div className="w-full">
                            <Link to="/checkout" className="text-gray-600 inline-flex justify-center items-center border rounded-md p-2 shadow-lg mt-2 mb-20 w-full bg-gradient-to-br from-blue-300 to-green-100">
                                Proceed to Checkout
                            </Link>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
};

export default Cart;