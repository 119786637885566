import React from 'react'

const PageWrapper = props => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="overflow-y-scroll overflow-x-hidden">
            {props.children}
        </div>
    )
}

export default PageWrapper;



