import React from 'react'

const ContentWrapper = props => {
    return (
        <div className="flex flex-col w-full h-full overflow-hidden">
            {props.children}
        </div>
    )
}

export default ContentWrapper;