import React from 'react'

const Notification = props => {
    let color;
    let svgPath;
    if (props.type === 'success') {
        color = 'green';
        svgPath = 'M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
    } else if (props.type === 'error') {
        color = 'red';
        svgPath = 'M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z';
    } else if (props.type === 'warning') {
        color = 'yellow';
        svgPath = 'M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z';
    } else if (props.type === 'info') {
        color = 'blue';
        svgPath = 'M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z';
    }

    return (
        <div className={`${props.show ? "ease-out translate-y-0 opacity-100 duration-200" : "invisible ease-in-out translate-y-2 opacity-0 duration-500"} fixed top-8 right-4 left-4 transform transition-all z-30`}>
            <div className={`shadow-xl rounded-md bg-${color}-50 p-4`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className={`h-5 w-5 text-${color}-400`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d={svgPath} clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="ml-3">
                        <h3 className={`text-sm font-medium text-${color}-800`}>
                            {props.message}
                        </h3>
                    </div>
                </div>
            </div>
            {/* Includes css classes in build */}
            <span className="hidden bg-green-50"></span>
            <span className="hidden text-green-400"></span>
            <span className="hidden text-green-800"></span>
            <span className="hidden bg-red-50"></span>
            <span className="hidden text-red-400"></span>
            <span className="hidden text-red-800"></span>
            <span className="hidden bg-yellow-50"></span>
            <span className="hidden text-yellow-400"></span>
            <span className="hidden text-yellow-800"></span>
            <span className="hidden bg-blue-50"></span>
            <span className="hidden text-blue-400"></span>
            <span className="hidden text-blue-800"></span>
        </div >
    )
};

export default Notification;