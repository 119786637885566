import React from 'react'
import { auth } from '../../firebase'
import { useSidebarContext, useSetSidebarContext } from '../../contexts/SidebarContext'

import SidebarAccount from './SidebarAccount'
import SidebarLink from './SidebarLink'
import SidebarSection from './SidebarSection';
import SidebarTitle from './SidebarTitle'

const Sidebar = props => {
    const sidebar = useSidebarContext();
    const setSidebar = useSetSidebarContext();

    return (
        <div className="h-screen absolute flex overflow-hidden bg-gray-100">
            <div className={`${sidebar ? "visible duration-300" : "invisible duration-200"} transition-visible md:hidden`}>
                <div className="fixed inset-0 flex z-40">
                    <div className={`${sidebar ? "opacity-100 duration-300" : "opacity-0 duration-200"} transition-opacity ease-linear fixed inset-0`}>
                        <div className="absolute inset-0 bg-gray-400 opacity-75"></div>
                    </div>
                    <div className={`${sidebar ? "translate-x-0 duration-300" : "-translate-x-full duration-200"} transition ease-in-out transform relative flex flex-1 flex-col w-full bg-white`}>
                        <div className={`${sidebar ? "visible" : "invisible"} transition-visible duration-75 absolute top-0 right-0 -mr-12 pt-2`}>
                            <button onClick={() => setSidebar(false)} className="flex items-center justify-center h-10 w-10 rounded-full">
                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="flex-1 overflow-y-auto">
                            <SidebarTitle name={"Trevor's Spoon Rings"} url={"/"} />
                            <nav>
                                <SidebarSection>
                                    <SidebarLink name={"Home"} icon={"fas fa-home fa-lg text-indigo-500 opacity-50"} url={"/"} />
                                </SidebarSection>
                                <SidebarSection>
                                    <SidebarLink name={"Rings"} icon={"fas fa-ring fa-lg text-yellow-500 opacity-50"} url={"/products?type=ring&page=1"} />
                                    <SidebarLink name={"Necklaces"} icon={"fas fa-gem fa-lg text-blue-500 opacity-50"} url={"/products?type=necklace&page=1"} />
                                    <SidebarLink name={"Other"} icon={"fas fa-tag fa-lg text-green-500 opacity-50"} url={"/products?type=other&page=1"} />
                                </SidebarSection>
                                {!auth.currentUser ? null :
                                    <SidebarSection>
                                        <SidebarLink name={"Orders"} icon={"fas fa-box fa-lg text-gray-400"} url={"/orders"} />
                                        <SidebarLink name={"Inventory"} icon={"fas fa-tags fa-lg text-gray-400"} url={"/inventory"} />
                                        <SidebarLink name={"New Product"} icon={"fas fa-pencil-ruler fa-lg text-gray-400"} url={"/new-product"} />
                                    </SidebarSection>
                                }
                                <SidebarSection hideBorder={true}>
                                    {!auth.currentUser ?
                                        <SidebarLink name={"Sign In"} icon={"fas fa-door-open fa-lg text-gray-400"} url={"/signin"} /> :
                                        <div onClick={() => auth.signOut()}>
                                            <SidebarLink name={"Logout"} icon={"fas fa-sign-out-alt fa-lg text-red-500 opacity-50"} url={"/"} />
                                        </div>
                                    }
                                </SidebarSection>
                            </nav>
                        </div>
                        <SidebarAccount />
                    </div>
                    <div className="flex-shrink-0 w-14">
                    </div>
                </div>
            </div >
        </div>
    )
};

export default Sidebar;