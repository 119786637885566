import React from 'react'

const CheckoutContext = React.createContext();
const SetCheckoutContext = React.createContext();

export const useCheckoutContext = () => React.useContext(CheckoutContext);
export const useSetCheckoutContext = () => React.useContext(SetCheckoutContext);

const CheckoutContextProvider = props => {
    const [checkout, setCheckout] = React.useState({
        customer: {
            fname: '',
            lname: '',
            email: '',
            phone: '',
        },
        shipping: {
            street: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        }
    });

    return (
        <CheckoutContext.Provider value={checkout}>
            <SetCheckoutContext.Provider value={setCheckout}>
                {props.children}
            </SetCheckoutContext.Provider>
        </CheckoutContext.Provider>
    )
}

export default CheckoutContextProvider;