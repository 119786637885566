import React from 'react'

import ImageGallery from 'react-image-gallery'
import ImageUpload from '../ImageUpload/ImageUpload'
import 'react-image-gallery/styles/css/image-gallery.css'

const UploadCarousel = props => {
    const [items, setItems] = React.useState([]);
    const getItems = () => {
        let imgs = [];
        props.images.forEach(image => {
            imgs.push({
                original: URL.createObjectURL(image),
                thumbnail: URL.createObjectURL(image),
                originalAlt: image.name,
                thumbnailAlt: image.name,
            })
        })
        setItems(imgs);
    }

    React.useEffect(getItems, [props.images])

    if (props.images && props.images.length > 0) {
        return (
            <div className="bg-gray-50 shadow border border-gray-100 rounded px-1 pt-1 pb-0.5 mx-2 relative z-0">
                <label htmlFor="upload" className="absolute text-sm text-white bg-gray-400 border-2 border-gray-500 rounded shadow flex justify-center items-center left-4 top-4 z-10 w-16 h-8">
                    <span>Upload</span>
                    <input type="file" id="upload" accept="image/*" multiple onChange={props.upload} hidden />
                </label>
                <button onClick={props.remove} className="absolute text-sm text-white bg-red-400 shadow border-2 border-red-500 rounded flex justify-center items-center right-4 top-4 z-10 w-16 h-8">
                    <span>Remove</span>
                </button>
                <ImageGallery items={items} slideOnThumbnailOver={true} stopPropagation={true} slideDuration={300} showPlayButton={false} showFullscreenButton={true} showBullets={true} />
            </div>
        )
    } else {
        return (
            <ImageUpload upload={props.upload} />
        )
    }
}

export default UploadCarousel;