import { React, useEffect, useRef } from 'react'

const ScrollToTop = () => {
    const top = useRef(null);

    useEffect(() => {
        top.current.scrollIntoView();
    }, [])

    return (
        <div ref={top}></div>
    )
};

export default ScrollToTop;