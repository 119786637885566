import React from 'react'

const CartContext = React.createContext();
const SetCartContext = React.createContext();

export const useCartContext = () => React.useContext(CartContext);
export const useSetCartContext = () => React.useContext(SetCartContext);

const CartContextProvider = props => {
    const [cart, setCart] = React.useState({
        numItems: 0,
        items: {},
    });

    return (
        <CartContext.Provider value={cart}>
            <SetCartContext.Provider value={setCart}>
                {props.children}
            </SetCartContext.Provider>
        </CartContext.Provider>
    )
}

export default CartContextProvider;