import React from 'react'
import { Link } from 'react-router-dom'

const Pagination = props => {
    return (
        <div className="flex items-stretch justify-between mb-3 h-8">
            <Link to={props.baseURL + (parseInt(props.page) - 1)} className="w-14 inline-flex justify-center items-center border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white">
                Prev
            </Link>
            <nav className="inline-flex justify-center rounded-md" aria-label="Pagination">
                {[...Array(props.numPages)].map((e, index) => {
                    return (
                        <div key={index}>
                            {index === 0 ?
                                props.numPages === 1 ?
                                    <Link to={props.baseURL + (parseInt(index) + 1)} className={`${index === props.page - 1 ? "bg-gray-300" : "bg-white"} h-8 inline-flex items-center px-3 rounded-md border border-gray-300 text-sm font-medium text-gray-700`}>
                                        {index + 1}
                                    </Link> :
                                    <Link to={props.baseURL + (parseInt(index) + 1)} className={`${index === props.page - 1 ? "bg-gray-300" : "bg-white"} h-8 inline-flex items-center px-3 rounded-l-md border border-gray-300 text-sm font-medium text-gray-700`}>
                                        {index + 1}
                                    </Link> :
                                index === props.numPages - 1 ?
                                    <Link to={props.baseURL + (parseInt(index) + 1)} className={`${index === props.page - 1 ? "bg-gray-300" : "bg-white"} h-8 inline-flex items-center px-3 rounded-r-md border border-gray-300 text-sm font-medium text-gray-700`}>
                                        {index + 1}
                                    </Link> :
                                    <Link to={props.baseURL + (parseInt(index) + 1)} className={`${index === props.page - 1 ? "bg-gray-300" : "bg-white"} h-8 inline-flex items-center px-3 border border-gray-300 text-sm font-medium text-gray-700`}>
                                        {index + 1}
                                    </Link>
                            }
                        </div>
                    )
                })}
            </nav>
            <Link to={props.baseURL + (parseInt(props.page) + 1)} className="w-14 inline-flex justify-center items-center border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white">
                Next
            </Link>
        </div>
    )
};

export default Pagination;