import React from 'react'

const SidebarSection = props => {
    return (
        <div className={`${props.hideBorder ? "" : "border-b border-gray-200 shadow-sm"} space-y-1 py-2`}>
            {props.children}
        </div>
    )
};

export default SidebarSection;