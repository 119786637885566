import React from 'react'
import { firestore } from '../../firebase'
import { Link } from 'react-router-dom';
import { useGetCollection } from '../../hooks/useGetCollection'
import { useSetNotificationContext } from '../../contexts/NotificationContext'
import PageTitle from '../../components/PageTitle/PageTitle'
import Toggle from '../../components/Toggle/Toggle'
import NotFound from '../NotFound/NotFound'

const Orders = props => {
    const [orders, setOrders] = React.useState(undefined);
    const [customers, setCustomers] = React.useState(undefined);
    const ordersData = useGetCollection("orders");
    const customersData = useGetCollection("customers");
    const notify = useSetNotificationContext();

    React.useEffect(() => {
        if (ordersData === undefined || customersData === undefined) return;
        setOrders(ordersData.data);
        setCustomers(customersData.data);
    }, [ordersData, customersData])

    const toggleShipped = (key, shipped) => {
        if (shipped === false) {
            let today = new Date();
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0');
            let yyyy = today.getFullYear();
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            today = mm + '-' + dd + '-' + yyyy + "_" + time;

            notify("Order marked as shipped", "info");
            let newOrders = { ...orders };
            newOrders[key].shipDate = today;
            setOrders(newOrders);
            firestore.collection('orders').doc(key).update({ shipDate: today });
        }
    }

    return (
        <div>
            {orders === undefined || customers === undefined ? null :
                Object.keys(orders).length === 0 ?
                    <NotFound /> :
                    <div className="mb-20">
                        <div className="border-b">
                            <PageTitle title="Orders" />
                        </div>
                        <nav className="h-full overflow-y-auto overflow-x-hidden">
                            <ul className="relative z-0 divide-y divide-gray-200">
                                {Object.entries(orders).map(([key, value]) => (
                                    <li key={key} className="bg-white p-2 px-3">
                                        <div className="relative flex justify-between w-full">
                                            <Link to={`/orders/${key}`} className="w-full flex flex-col">
                                                <div className="text-gray-700">
                                                    {customers[value.email.trim()]?.fname + ' ' + customers[value.email.trim()]?.lname}
                                                    <span className="text-gray-500 text-xs">
                                                        - {value.purchaseDate.replaceAll("-", "/").split('_')[0]}
                                                    </span>
                                                </div>
                                                <div className="text-sm text-gray-600 truncate">
                                                    {value.email}
                                                </div>
                                                <div className="text-xs text-gray-500 truncate">
                                                    Phone: {customers[value.email.trim()]?.phone}
                                                </div>
                                            </Link>
                                            <div className="inline-flex justify-center items-center pr-4 pl-1 text-blue-500">
                                                ${value.amount}
                                            </div>
                                            <div className="flex flex-col space-y-2 justify-center">
                                                <div className="text-xs text-gray-500 text-center">
                                                    Shipped
                                                </div>
                                                <div className="inline-flex items-center justify-center">
                                                    <Toggle enabled={value.shipDate !== '' && value.shipDate !== null} toggle={() => { toggleShipped(key, value.shipDate !== '' && value.shipDate !== null ? true : false) }} disabled={value.shipDate !== '' && value.shipDate !== null} />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
            }
        </div>
    )
};

export default Orders;