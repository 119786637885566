import React from 'react'
import Notification from '../components/Notification/Notification';

const SetNotificationContext = React.createContext();
export const useSetNotificationContext = () => React.useContext(SetNotificationContext);

const NotificationContextProvider = props => {
    const [notification, setNotification] = React.useState({ show: false, message: '', type: 'success' });

    const notify = (message, type) => {
        setNotification({
            show: true,
            message: message,
            type: type,
        });
        setTimeout(() => setNotification(prev => ({
            ...prev,
            show: false,
        })), 2000);
    }

    return (
        <SetNotificationContext.Provider value={notify}>
            <Notification show={notification.show} message={notification.message} type={notification.type} />
            {props.children}
        </SetNotificationContext.Provider>
    )
}

export default NotificationContextProvider;