import React, { useState } from 'react'
import { addProduct } from '../../firebase'
import { useSetNotificationContext } from '../../contexts/NotificationContext'
import { useSetLoaderContext } from '../../contexts/LoaderContext'

import PageTitle from '../../components/PageTitle/PageTitle'
import UploadCarousel from '../../components/Carousel/UploadCarousel'
import Modal from '../../components/Modal/Modal';

const initial = {
    name: '',
    description: '',
    price: 0,
    type: 'Ring',
    images: [],
    thumbs: [],
    inventory: [],
}

const NewProduct = () => {
    const notify = useSetNotificationContext();
    const setLoading = useSetLoaderContext();

    const [showModal, setModal] = useState(false);
    const [curItem, setCurItem] = useState({})
    const [product, setProduct] = useState(initial)
    const [images, setImages] = useState([]);

    const submit = async () => {
        setLoading(true);
        if (product.name === '') {
            notify("You must enter a name", "warning");
        } else if (product.description === '') {
            notify("You must enter a description", "warning");
        } else if (product.type === '') {
            notify("You must enter a type", "warning");
        } else if (product.price === '' || isNaN(product.price) || product.price === 0) {
            notify("You must enter a price greater than 0", "warning");
        } else if (images.length === 0) {
            notify("You must attach images", "warning");
        } else {
            await addProduct(product, images)
                .then(() => {
                    try {
                        setProduct(initial);
                        setImages([]);
                        setLoading(false);
                        notify("Product added successfully", "success")
                    } catch (error) {
                        notify(error, "error");
                    }
                })
                .catch(error => { notify(error.message, "error") });
        }
        setLoading(false);
    }

    const handleChange = (name, value) => {
        setProduct(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleInventory = () => {
        let inv = [...product.inventory];
        inv[curItem.id] = curItem;
        delete inv[curItem.id].id;
        handleChange('inventory', inv);
        setModal(false);
    }

    const removeInventory = index => {
        let inv = [...product.inventory];
        inv.splice(index, 1);
        handleChange('inventory', inv);
    }

    const handleImages = e => {
        setImages([...images, ...e.target.files]);
    }

    return (
        <div>
            <PageTitle title="New Product" />
            <UploadCarousel images={images} upload={handleImages} remove={() => setImages([])} />
            <div className="p-3 mb-3">
                <div className="">
                    <div className="w-full">
                        <label className="mb-1 text-sm font-medium text-gray-700">Name</label>
                        <input type="text" name="name" value={product.name} onChange={e => handleChange(e.target.name, e.target.value)} className="p-1.5 w-full shadow border border-gray-100 rounded" />
                    </div>
                </div>
                <div className="mt-2">
                    <label className="mb-1 text-sm font-medium text-gray-700">Description</label>
                    <textarea type="text" name="description" value={product.description} onChange={e => handleChange(e.target.name, e.target.value)} className="p-1.5 w-full shadow border border-gray-100 rounded" />
                </div>
                <div className="mt-2 flex justify-between items-center">
                    <div className="w-full mr-1">
                        <label className="mb-1 text-sm font-medium text-gray-700">Price</label>
                        <input type="number" name="price" value={product.price} onChange={e => handleChange(e.target.name, e.target.value)} className="p-1.5 w-full shadow border border-gray-100 rounded" min="0" />
                    </div>
                    <div className="w-full ml-1">
                        <label className="mb-1 text-sm font-medium text-gray-700">Type</label>
                        <select name="type" value={product.type} onChange={e => handleChange(e.target.name, e.target.value)} className="p-1.5 w-full shadow border border-gray-100 rounded">
                            <option>Ring</option>
                            <option>Necklace</option>
                            <option>Other</option>
                        </select>
                    </div>
                </div>
                <div className="mt-2">
                    <label className="mb-1 text-sm font-medium text-gray-700">Inventory</label>
                    <div className="bg-white shadow border border-gray-300 rounded">
                        {product.inventory.map((item, index) => (
                            <div key={index} className="text-sm text-gray-700 text-center border-b border-gray-200 p-2 flex items-center justify-between">
                                <div onClick={() => { setModal(true); setCurItem({ id: index, size: item.size, qty: item.qty }); }} className="border rounded px-1 text-gray-500 bg-blue-200 border-blue-300">Edit</div>
                                <div>Size: {item.size}</div>
                                <div>Qty: {item.qty}</div>
                                <div onClick={() => removeInventory(index)} className="border rounded px-1 bg-red-100 border-red-200 text-red-300"><i className="fas fa-times"></i></div>
                            </div>
                        ))}
                        <div onClick={() => { setModal(true); setCurItem({ id: product.inventory.length, size: '', qty: '' }); }} className="text-sm  text-gray-700 bg-gradient-to-br from-blue-300 to-green-100 text-center p-2 rounded rounded-t-none">ADD SIZE</div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center items-center mb-6">
                <button onClick={submit} className="w-1/2 h-10  text-gray-700 bg-white flex items-center justify-center rounded-md border border-gray-300 shadow focus:outline-none">Add Product</button>
            </div>

            <Modal show={showModal} close={() => setModal(false)}>
                <div className="sm:flex sm:items-start">
                    <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                            Edit Size
                        </h3>
                        <div className="mt-2 text-left">
                            <label className="mb-1 text-sm font-medium text-gray-700">Size</label>
                            <input value={curItem.size ? curItem.size : ''} onChange={e => setCurItem({ id: curItem.id, size: e.target.value, qty: curItem.qty })} type="text" className="p-1.5 w-full shadow border border-gray-100 rounded" />
                        </div>
                        <div className="mt-2 text-left">
                            <label className="mb-1 text-sm font-medium text-gray-700">Quantity</label>
                            <input value={curItem.qty ? curItem.qty : ''} onChange={e => setCurItem({ id: curItem.id, size: curItem.size, qty: e.target.value })} type="number" className="p-1.5 w-full shadow border border-gray-100 rounded" min="0" />
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button onClick={handleInventory} type="button" className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-gradient-to-br from-blue-300 to-green-100 font-medium text-gray-700">
                        Save
                        </button>
                    <button onClick={() => setModal(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-50 sm:mt-0 sm:w-auto sm:text-sm">
                        Cancel
                    </button>
                </div>
            </Modal>
        </div >
    )
}

export default React.memo(NewProduct);