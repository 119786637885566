import React from 'react'

const SidebarContext = React.createContext();
const SetSidebarContext = React.createContext();

export const useSidebarContext = () => React.useContext(SidebarContext);
export const useSetSidebarContext = () => React.useContext(SetSidebarContext);

const SidebarContextProvider = props => {
    const [sidebar, setSidebar] = React.useState(false);

    return (
        <SidebarContext.Provider value={sidebar}>
            <SetSidebarContext.Provider value={setSidebar}>
                {props.children}
            </SetSidebarContext.Provider>
        </SidebarContext.Provider>
    )
}

export default SidebarContextProvider;