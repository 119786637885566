import React from 'react'
import { Link } from 'react-router-dom'
import { useSetSidebarContext } from '../../contexts/SidebarContext'

const SidebarLink = props => {
    const setSidebar = useSetSidebarContext();
    return (
        <div className="px-2">
            <Link onClick={() => setSidebar(false)} to={props.url} className="text-gray-500 hover:bg-gray-50 group flex items-center px-2 py-2 font-medium rounded-md">
                <i className={`w-6 mr-4  ${props.icon}`} />
                {props.name}
            </Link>
        </div>
    )
};

export default SidebarLink;