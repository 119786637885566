import React from 'react'

const PageTitle = props => {
    return (
        <div className="py-2 w-full text-lg text-center font-fancy text-gray-700">
            {props.title}
        </div>
    )
}

export default PageTitle;