import React from 'react'
import { firestore } from '../../firebase'
import { Link } from 'react-router-dom';
import { useGetCollection } from '../../hooks/useGetCollection'
import PageTitle from '../../components/PageTitle/PageTitle'
import Toggle from '../../components/Toggle/Toggle'
import NotFound from '../NotFound/NotFound'

const Inventory = props => {
    const [products, setProducts] = React.useState(undefined);
    const { data } = useGetCollection("products");

    React.useEffect(() => {
        if (data === undefined) return;
        setProducts(setTotalQty(data));
    }, [data])

    const setTotalQty = data => {
        let nd = { ...data };
        Object.entries(nd).forEach(([key, value]) => {
            let total = 0;
            value.inventory.forEach(item => {
                total += parseInt(item.qty)
            });
            nd[key].totalInventory = total;
        })
        return nd;
    }

    const toggleProduct = (key, enabled) => {
        firestore.collection('products').doc(key).update({ enabled: !enabled });
        let newProducts = { ...products };
        newProducts[key].enabled = !enabled;
        setProducts(newProducts);
    }

    return (
        <div>
            {products === undefined ? null :
                Object.keys(products).length === 0 ?
                    <NotFound /> :
                    <div className="mb-20">
                        <div className="border-b">
                            <PageTitle title="Inventory" />
                        </div>
                        <nav className="h-full overflow-y-auto overflow-x-hidden">
                            <ul className="relative z-0 divide-y divide-gray-200">
                                {Object.entries(products).map(([key, value]) => (
                                    <li key={key} className="bg-white">
                                        <div className="relative flex w-full">
                                            <div className="flex-shrink-0 p-1">
                                                <img className="h-full w-24 rounded" src={value.images[0]} alt={key} />
                                            </div>
                                            <Link to={`/inventory/${key}`} className="flex flex-col p-2 truncate w-full">
                                                <div className="flex flex-col">
                                                    <div className="text-md font-medium text-gray-700 truncate">
                                                        {value.name}
                                                    </div>
                                                    <div className="text-xs text-gray-500 truncate mb-1">
                                                        {value.description}
                                                    </div>
                                                    <div className="flex justify-between items-center">
                                                        <div className="text-blue-500">
                                                            ${parseFloat(value.price).toFixed(2)}
                                                        </div>
                                                        <div className="text-gray-600 text-sm">
                                                            Inventory: {value.totalInventory}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className="flex flex-col space-y-1 justify-center p-2">
                                                <div className="text-xs text-gray-500 text-center">
                                                    Show
                                                </div>
                                                <div className="inline-flex items-center justify-center">
                                                    <Toggle enabled={value.enabled} toggle={() => { toggleProduct(key, value.enabled) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
            }
        </div>
    )
};

export default Inventory;