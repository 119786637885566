import React from 'react';

const AppWrapper = props => {
    return (
        <div className="h-screen w-full overflow-hidden bg-gray-100">
            {props.children}
        </div>
    )
}

export default AppWrapper;