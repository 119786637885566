import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// const functions = require("firebase-functions");
// const cors = require('cors')({origin: true});

// exports.stripePayment = functions.https.onRequest((request,response) => {
//     cors(request, response, async () => {
//         let stripe = require('stripe')(functions.config().stripe.secret);

//         console.log("START OF FUNCTION");
//         const firestoreCustomer = request.body.customer;
//         console.log("FIRESTORE CUSTOMER",firestoreCustomer);
//         let customer;
//         if (firestoreCustomer.hasStripeId === false) {
//             customer = await stripe.customers.create({
//                 id: customer.email,
//                 name: customer.fname + " " + customer.lname,
//                 email: customer.email,
//                 phone: customer.phone,
//                 shipping: customer.address,
//             });
//         } else {
//             customer = await stripe.customers.retrieve(firestoreCustomer.email);
//         }
//         console.log("STRIPE CUSTOMER", customer);
//         const session = await stripe.checkout.sessions.create({
//             success_url: 'http://localhost:3000/success',
//             cancel_url: 'http://localhost:3000/cart',
//             payment_method_types: ['card'],
//             customer: customer.id,
//             shipping: customer.shipping,
//             customer_email: customer.email,
//             line_items: [
//             {
//                 price_data: {
//                 currency: 'usd',
//                 product_data: {
//                     name: 'T-shirt',
//                 },
//                 unit_amount: 2000,
//                 },
//                 quantity: 1,
//             },
//             ],
//             mode: 'payment',
//         })
//         console.log("SESSION", session);

//         response.json({ id: session.id });
//     })
// })