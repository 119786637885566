import { useState, useEffect, useRef } from 'react'
import { firestore } from '../firebase'

export const useGetCollection = (name, page, itemsPerPage, query) => {
    const cache = useRef({});
    const [data, setData] = useState();
    const [numPages, setNumPages] = useState();

    const fetchCollection = async (name,page,itemsPerPage,query) => {
        let collection = {};
        if (cache.current[name]) {
            collection = cache.current[name];
        } else {
            await firestore.collection(name).get()
            .then(items => items.forEach(item => collection[item.id] = item.data()));
            cache.current[name] = collection;
        }

        let collectionAsArray = Object.entries(collection);
        if (query !== undefined) {
            for (let i = 0; i < query.length; i++) {
                if (query[i][1] === undefined || query[i][1] === null) continue;
                if (typeof query[i][1] == "string") {
                    collectionAsArray = collectionAsArray.filter(([key,value]) => value[query[i][0]].toLowerCase() === query[i][1].toLowerCase())
                } else {
                    collectionAsArray = collectionAsArray.filter(([key,value]) => value[query[i][0]] === query[i][1])
                }
            }
        }

        if (page !== undefined && itemsPerPage !== undefined) {
            setNumPages(Math.ceil(collectionAsArray.length/itemsPerPage));
            if (page < 1) page = 1;
            if (page > numPages) page = numPages;
            let start = (itemsPerPage * page) - itemsPerPage;
            let end = start + itemsPerPage;
            if ((start + itemsPerPage) > collectionAsArray.length) {
                collectionAsArray = collectionAsArray.slice(start);
            } else {
                collectionAsArray = collectionAsArray.slice(start,end);
            }
        }
        setData(Object.fromEntries(collectionAsArray));
    }

    useEffect(() => {
        fetchCollection(name,page,itemsPerPage,query);
    },[page,itemsPerPage,JSON.stringify(query)]);

    return { data, numPages };
}