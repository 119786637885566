import React, { useState } from 'react'
import { firestore, storage } from '../../firebase'
import { useParams, useHistory } from 'react-router';
import { useGetDocument } from '../../hooks/useGetDocument'
import { useSetNotificationContext } from '../../contexts/NotificationContext'

import Modal from '../../components/Modal/Modal';

const Item = () => {
    const [showModal, setModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [product, setProduct] = useState(undefined);
    const [curItem, setCurItem] = useState({});

    const { id } = useParams();
    const history = useHistory();
    const data = useGetDocument("products", id);
    const notify = useSetNotificationContext();

    React.useEffect(() => {
        if (data === undefined) return;
        setProduct(data);
    }, [data])

    const submit = async () => {
        if (product.name === '') {
            notify("You must enter a name", "warning");
        } else if (product.description === '') {
            notify("You must enter a description", "warning");
        } else if (product.type === '') {
            notify("You must enter a type", "warning");
        } else if (product.price === '' || isNaN(product.price) || product.price === 0) {
            notify("You must enter a price greater than 0", "warning");
        } else {
            firestore.collection("products").doc(id).update({
                ...product
            }).then(() => {
                notify("updated", "info");
            })
        }
    }

    const deleteItem = async () => {
        firestore.collection("products").doc(id).delete().then(() => {
            notify("deleted", 'success');
            product.images.forEach((image, index) => {
                const image200 = storage.child(`images/${id}/image_${index}_200x200.jpeg`);
                const image800 = storage.child(`images/${id}/image_${index}_800x800.jpeg`);
                image200.delete();
                image800.delete();
            })
        })
        history.push("/inventory")
    }

    const handleChange = (name, value) => {
        setProduct(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleInventory = () => {
        let inv = [...product.inventory];
        inv[curItem.id] = curItem;
        delete inv[curItem.id].id;
        handleChange('inventory', inv);
        setModal(false);
    }

    const removeInventory = index => {
        let inv = [...product.inventory];
        inv.splice(index, 1);
        handleChange('inventory', inv);
    }

    return (
        <div>
            {product === undefined ? null :
                <div>
                    <div className="p-3 space-y-2">
                        <div className="flex-none">
                            <img src={product.images[0]} alt={product.name} className="w-full h-full object-cover rounded-lg" />
                        </div>
                        <div className="w-full">
                            <label className="mb-1 text-sm font-medium text-gray-700">Name</label>
                            <input type="text" name="name" value={product.name} className="p-1.5 w-full shadow bg-gray-100 border border-gray-100 rounded" disabled />
                        </div>
                        <div>
                            <label className="mb-1 text-sm font-medium text-gray-700">Description</label>
                            <textarea type="text" name="description" value={product.description} onChange={e => handleChange(e.target.name, e.target.value)} className="p-1.5 w-full shadow border border-gray-100 rounded" />
                        </div>
                        <div className="flex justify-between items-center space-x-2">
                            <div className="w-full">
                                <label className="text-sm font-medium text-gray-700">Price</label>
                                <input type="number" name="price" value={product.price} onChange={e => handleChange(e.target.name, e.target.value)} className="p-1.5 w-full shadow border border-gray-100 rounded" min="0" />
                            </div>
                            <div className="w-full">
                                <label className="text-sm font-medium text-gray-700">Type</label>
                                <select name="type" value={product.type} onChange={e => handleChange(e.target.name, e.target.value)} className="p-1.5 w-full shadow border border-gray-100 rounded">
                                    <option>Ring</option>
                                    <option>Necklace</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="pt-1">
                            <label className="mb-1 text-sm font-medium text-gray-700">Inventory</label>
                            <div className="bg-white shadow border border-gray-300 rounded">
                                {product.inventory.map((item, index) => (
                                    <div key={index} className="text-sm text-gray-700 text-center border-b border-gray-200 p-2 flex items-center justify-between">
                                        <div onClick={() => { setModal(true); setCurItem({ id: index, size: item.size, qty: item.qty }); }} className="border rounded px-1 text-gray-500 bg-blue-200 border-blue-300">Edit</div>
                                        <div>Size: {item.size}</div>
                                        <div>Qty: {item.qty}</div>
                                        <div onClick={() => removeInventory(index)} className="border rounded px-1 bg-red-100 border-red-200 text-red-300"><i className="fas fa-times"></i></div>
                                    </div>
                                ))}
                                <div onClick={() => { setModal(true); setCurItem({ id: product.inventory.length, size: '', qty: '' }); }} className="text-sm  text-gray-700 bg-gradient-to-br from-blue-300 to-green-100 text-center p-2 rounded rounded-t-none">ADD SIZE</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center space-x-2 px-2 mb-4 mt-3">
                        <button onClick={() => submit()} className="w-1/2 h-10 text-gray-700 bg-white flex items-center justify-center rounded-md border border-gray-300 shadow focus:outline-none">
                            Update
                        </button>
                        <button onClick={() => setShowDelete(true)} className="w-1/2 h-10 text-red-400 bg-red-100 flex items-center justify-center rounded-md border border-red-200 shadow focus:outline-none">
                            Delete
                        </button>
                    </div>
                    {data.images.map((image, index) => {
                        return (
                            <div key={index} className="px-3">
                                {index === 0 && data.images.length > 1 ?
                                    <div className="mb-1 text-md text-center text-gray 700 italic">
                                        Additional Images
                                    </div> : ''
                                }
                                {index !== 0 ?
                                    <div className="flex-none mb-10">
                                        <img src={image} alt={data.name} className="w-full h-full object-cover rounded-lg" />
                                    </div> : ''
                                }
                            </div>
                        )
                    })}

                    <Modal show={showModal} close={() => setModal(false)}>
                        <div className="text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Edit Size
                            </h3>
                            <div className="mt-2 text-left">
                                <label className="mb-1 text-sm font-medium text-gray-700">Size</label>
                                <input value={curItem.size ? curItem.size : ''} onChange={e => setCurItem({ id: curItem.id, size: e.target.value, qty: curItem.qty })} type="text" className="p-1.5 w-full shadow border border-gray-100 rounded" />
                            </div>
                            <div className="mt-2 text-left">
                                <label className="mb-1 text-sm font-medium text-gray-700">Quantity</label>
                                <input value={curItem.qty ? curItem.qty : ''} onChange={e => setCurItem({ id: curItem.id, size: curItem.size, qty: e.target.value })} type="number" className="p-1.5 w-full shadow border border-gray-100 rounded" min="0" />
                            </div>
                        </div>
                        <div className="mt-5">
                            <button onClick={handleInventory} type="button" className="w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-gradient-to-br from-blue-300 to-green-100 font-medium text-gray-700">
                                Save
                            </button>
                            <button onClick={() => setModal(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700">
                                Cancel
                            </button>
                        </div>
                    </Modal>
                    <Modal show={showDelete} close={() => setShowDelete(false)}>
                        <div>
                            <div className="text-md mb-4">
                                Are you sure your want to delete this product?
                            </div>
                            <div className="flex space-x-2">
                                <button onClick={() => deleteItem()} className="py-1 bg-red-100 border-red-200 w-full inline-flex justify-center items-center rounded-md border shadow text-red-400 font-medium">
                                    Delete
                                </button>
                                <button onClick={() => setShowDelete(false)} className="py-1 w-full inline-flex justify-center items-center rounded-md border shadow text-gray-600 font-medium">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div >
            }
        </div >
    )
}

export default Item;