import React from 'react'

const SetLoaderContext = React.createContext();
export const useSetLoaderContext = () => React.useContext(SetLoaderContext);

const LoaderContextProvider = props => {
    const [loading, setLoading] = React.useState(false);

    return (
        <SetLoaderContext.Provider value={setLoading}>
            {!loading ? null :
                <div>
                    <div className="fixed inline-flex justify-center items-center mt-40 w-full animate-spin text-gray-400">
                        <i className="fas fa-spinner fa-3x"></i>
                    </div>
                </div>
            }
            { props.children}
        </SetLoaderContext.Provider >
    )
}

export default LoaderContextProvider;