import React from 'react'
import { Switch, Route } from 'react-router-dom'
import CartContextProvider from './contexts/CartContext'
import CheckoutContextProvider from './contexts/CheckoutContext'
import LoaderContextProvider from './contexts/LoaderContext'
import NotificationContextProvider from './contexts/NotificationContext'
import SidebarContextProvider from './contexts/SidebarContext'

import AppWrapper from './layouts/AppWrapper/AppWrapper'
import ContentWrapper from './layouts/ContentWrapper/ContentWrapper'
import PageWrapper from './layouts/PageWrapper/PageWrapper'
import Sidebar from './layouts/Sidebar/Sidebar'
import Topbar from './layouts/Topbar/Topbar'
import Cart from './pages/Cart/Cart'
import Checkout from './pages/Checkout/Checkout'
import Home from './pages/Home/Home'
import NewProduct from './pages/NewProduct/NewProduct'
import NotFound from './pages/NotFound/NotFound'
import Product from './pages/Product/Product'
import Products from './pages/Products/Products'

// Stripe
import { stripeKey } from './firebase'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from "@stripe/react-stripe-js";
import Inventory from './pages/Inventory/Inventory'
import Item from './pages/Item/Item'
import Orders from './pages/Orders/Orders'
import Order from './pages/Order/Order'
import SignIn from './pages/SignIn/SignIn'
const stripePromise = loadStripe(stripeKey,{stripeAccount: "acct_1GschTDa4nKoSaqF"});

const App = () => {
  return (
    <LoaderContextProvider>
      <NotificationContextProvider>
        <CheckoutContextProvider>
          <CartContextProvider>
            <SidebarContextProvider>
              <AppWrapper>
                <ContentWrapper>
                  <Topbar />
                  <PageWrapper>
                    <Switch>
                      <Route exact path='/'>
                        <Home/>
                      </Route>
                      <Route path='/cart'>
                        <Cart/>
                      </Route>
                      <Route path='/checkout'>
                        <Elements stripe={stripePromise}>
                          <Checkout/>
                        </Elements>
                      </Route>
                      <Route exact path='/products'>
                        <Products/>
                      </Route>
                      <Route path='/products/:id'>
                        <Product/>
                      </Route>
                      <Route exact path='/orders'>
                        <Orders/>
                      </Route>
                      <Route path='/orders/:id'>
                        <Order/>
                      </Route>
                      <Route exact path='/inventory'>
                        <Inventory />
                      </Route>
                      <Route path='/inventory/:id'>
                        <Item />
                      </Route>
                      <Route path='/new-product'>
                        <NewProduct />
                      </Route>
                      <Route path='/signin'>
                        <SignIn/>
                      </Route>
                      <Route path="*">
                        <NotFound/>
                      </Route>
                    </Switch>
                  </PageWrapper>
                </ContentWrapper>
                <Sidebar />
              </AppWrapper>
            </SidebarContextProvider>
          </CartContextProvider>
        </CheckoutContextProvider>
      </NotificationContextProvider>
    </LoaderContextProvider>
  )

}

export default App;
