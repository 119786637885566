import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div>
            <div className="flex flex-col p-4 justify-center items-center text-gray-700">
                <div className="pb-2">
                    Hmm...
                        </div>
                <div className="pb-2">
                    There doesn't seem to be anthing here.
                        </div>
                <div className="w-full mb-10">
                    <img className="w-full" src="https://media.giphy.com/media/tvU9iTev6uBIQ/giphy.gif" alt="shrug" />
                </div>
                <Link to="/" className="border p-2 rounded-md shadow bg-white">
                    Explore somewhere else...
            </Link>
            </div>
        </div>
    )
};

export default NotFound;